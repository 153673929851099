@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.cartContainer {
  background-color: $clr-white;
  height: 100%;
  overflow: hidden;
  padding: 24px 24px 180px;
  width: max-content;

  &.collapsed {
    min-width: auto;
    padding: 18px 0 28px;
    width: 80px;
  }

  @media (max-width: $gl-breakpoint-webflow-mobile) {
    height: calc(100% - #{$gl-webflow-desktop-header-height});
    position: fixed;
    right: 0;
    z-index: $gl-z-index-overlay;

    &.collapsed {
      height: 100%;
      position: static;
    }
  }

  @include mobile {
    height: calc(100% - #{$gl-webflow-mobile-header-height});
    min-width: auto;
    padding-bottom: 120px;
    width: 100%;

    &.collapsed {
      display: none;
    }
  }

  @include phone {
    height: calc(100% - #{$gl-webflow-phone-header-height});
  }
}

.cartHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  margin-bottom: 24px;
  border-bottom: 1px solid #e2dfdd;

  &.collapsed {
    height: 100%;
    margin: 0;
  }
}

.cartHeaderTitle {
  font-size: $gl-font-size-overline;
  align-items: center;
  display: flex;
  font-weight: $gl-font-weight-regular;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: $clr-black-medium;
  margin: 0;

  &.collapsed {
    margin-top: 25px;
  }
}

.cartToggle {
  align-items: center;
  background: none;
  border: none;
  display: flex;
  height: 24px;
  justify-content: center;
  margin-right: 4px;
  outline: none;
  width: 24px;
  z-index: 99;

  &.collapsed {
    align-items: flex-start;
    display: flex;
    height: 100%;
    margin: 0 auto;
    padding: 5px 10px 10px;
    width: auto;

    .cartToggleContent {
      flex-direction: row-reverse;
      justify-content: flex-end;
      writing-mode: tb;
    }
  }
}

.cartToggleContent {
  align-items: center;
  display: flex;
  justify-content: center;
}

.cartCostSummary {
  padding: 0 !important;
  min-width: 352px;

  @include mobile {
    margin: 0 auto;
    max-width: 500px;
    width: 100%;
  }

  @include phone {
    min-width: 200px;
  }
}

.costSummaryHeader {
  @include font-size($gl-font-size-xl);
  color: $clr-primary;
  font-family: $gl-font-family-page-header;
  margin-top: 6px;

  @media (max-width: 991px) {
    display: none;
  }
}

.spacer {
  display: block;

  @media (max-width: 991px) {
    display: block;
  }
}

.icon {
  color: $clr-background-light;

  &:hover {
    color: $clr-gray-divider;
  }
}
