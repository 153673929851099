@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.documentationContainer {
  background-color: $clr-white;
  border-left: 1px solid #ced4db;
  height: 100%;
  overflow: hidden;
  width: 600px;

  &.fontello {
    width: 700px;
  }

  @media (max-width: $gl-breakpoint-webflow-mobile) {
    position: fixed;
    z-index: $gl-z-index-overlay + 1;
    right: 0;
  }

  @media (max-width: 600px) {
    border-left: none;
    width: 100%;
  }
}

.documentation {
  height: 100%;
  position: relative;
}

.documentationIframe {
  border: none;
  height: 100%;
  width: 100%;
}

.documentationHeader {
  align-items: center;
  background-color: $clr-white;
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;
  top: 0;
  width: 100%;
}

.documentationHeaderTitle {
  font-size: $gl-font-size-overline;
  align-items: center;
  display: flex;
  font-weight: $gl-font-weight-regular;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: $clr-black-medium;
  margin: 0;
}

.documentHeaderTitleIcon {
  margin-left: 10px;
}

.documentationHeaderTitleLoader {
  margin-left: 8px;
}

.iconClose {
  color: $clr-background-light;

  &:hover {
    color: $clr-gray-divider;
  }
}
