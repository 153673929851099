@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
$switch-height: 14px;
$switch-width: 34px;

$toggle-size: 20px;

.switch {
  background-color: #c4cbd1;
  border-radius: 8px;
  height: $switch-height;
  justify-content: flex-start;
  transition: all 0.2s;
  width: $switch-width;

  outline: 3px solid transparent; // Invisible by default
  outline-offset: 4px;

  &.active {
    background-color: $clr-primary;
    border: 1px solid $clr-gray-dark;

    .toggle {
      background-color: #bcc8d4;
      transform: translate(#{$switch-width - $toggle-size}, 0);
      border: 2px solid $clr-primary-500;
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover {
    outline-color: $clr-background-light;
  }
}

.toggle {
  background-color: #f7f8fc;
  border-radius: 50%;
  height: $toggle-size;
  transform: translate(-2px, 0);
  border: 2px solid #05092b40;
  transition: all 0.2s;
  width: $toggle-size;
}

.switchContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  padding: 3px 0;
}

.labelInline {
  margin: 0;
}
