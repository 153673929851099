@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.rightPanel {
  overflow: hidden;
  position: relative;
  box-shadow: 3px 2px 16px 0px rgb(30 73 110 / 7%);

  &.showDocs {
    overflow: visible;
  }
}

.rightPanelHideItem {
  display: none !important;
}

.readyToPublishContainer {
  background-color: #62aae826;
  border: 1px solid #62aae840;
  border-radius: 8px;
  min-width: 339px;
  padding: 15px;
  position: relative;
  width: 100%;
  max-width: 352px;
  margin: 16px auto 0;

  @include mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.readyToPublishTitle {
  @include font-size($gl-font-size-m);
  color: $clr-black;
  font-weight: $gl-font-weight-regular;
  margin-bottom: 8px;
  position: relative;

  @include mobile {
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

.readyToPublishItem {
  @include font-size($gl-font-size-xs);

  @include mobile {
    display: flex;
    justify-content: center;
    text-align: center;
    padding-right: 0;
    margin: 0 auto;
  }
}

.readyToPublishButtonContainer {
  margin-top: 12px;
  width: 100%;

  @include mobile {
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

.readyToPublishButton {
  min-width: 320px;
}

.readyToPublishClose {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 9;
}
