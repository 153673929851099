@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.container {
  display: grid;
  grid-template-columns: 1fr min-content;
  height: 100%;
  overflow: hidden;
}

.main {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media screen and (max-width: $gl-breakpoint-webflow-phone-w) {
    display: block;
    overflow: auto;
  }

  &.dashboardMain {
    grid-template-rows: 1fr;
    margin-top: 16px;

    @media (max-width: $gl-breakpoint-webflow-phone-w) {
      margin-top: 0;
    }
  }
}

.content {
  display: flex;
  overflow: hidden;
}

.toggle {
  display: none !important;

  @include mobile {
    position: absolute;
    right: 0;
    align-items: center !important;
    background-color: $clr-black-high !important;
    opacity: 0.8;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    color: $clr-white;
    display: flex !important;
    padding: 12.5px 5px 12.5px 9px !important;
    z-index: $gl-z-index-overlay;
    top: 16px;

    &:hover {
      color: $clr-white;
    }
  }
}
