@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.shareContainer {
  padding-top: 40px;

  @media screen and (max-width: 767px) {
    padding: 30px 4px 0;
  }
}

.appHeader {
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 8px;
  flex-direction: column;
}

.appHeaderTitle {
  @include font-size($gl-font-size-4xl);
  line-height: 41.7px;
  color: $clr-primary;
  font-weight: $gl-font-weight-bold;
  margin: 0;
  text-align: center;
}

.appIconContainer {
  align-items: center;
  background-color: $clr-white;
  border-radius: 22.37%;
  display: flex;
  height: 72px;
  justify-content: center;
  margin-right: 8px;
  width: 72px;
  overflow: hidden;
  box-shadow: 2px 2px 16px 0px rgba(30, 73, 110, 0.07);

  &.small {
    margin-right: 0;
  }
}

.appIcon {
  height: 100%;
  width: 100%;
}
.preview {
  margin: 32px 0;
}

.appRebuild {
  padding-bottom: 180px;
  padding-top: 40px;

  @media screen and (max-width: 767px) {
    padding-top: 30px;
  }
}

// Copies styling in original app builder.
.appRebuildInfo {
  @include font-size($gl-font-size-m);
  line-height: 1.1;
  margin: 10.5px auto;
  max-width: 1180px;
}

.modal {
  display: flex;
  flex-direction: column;
  max-height: 750px;
  max-width: 650px;
  width: 100%;
  z-index: $gl-z-index-max - 20;

  @media (max-width: 767px) {
    border-radius: 0;
    max-height: none !important;
    max-width: none !important;
  }
}

.title {
  flex: 1;
  justify-content: center;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 16px;
}

.modalDescription {
  text-align: center;
  max-width: 420px;
}

.qrCode {
  height: auto;
  max-width: 150px;
  width: 150px;
}

.linkContent {
  &:hover {
    color: $clr-green;
  }
}

.iconLink {
  padding-top: 3px;
  margin-bottom: -2px;
}

.closeButton {
  align-items: flex-start !important;
}

.overlay {
  z-index: $gl-z-index-max - 20 !important;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.button {
  width: 343px;
}
