@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.button {
  width: 100%;
}

.buttonContainer {
  width: 100%;
  padding: 5px 25px 0;
}

.container {
  background-color: $clr-black;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  line-height: 1.4;
  max-width: 240px;
  padding: 15px 0 18px;
}

.error {
  @include font-size(13px);
  color: $clr-white;
  padding: 0 20px;
  text-align: center;
}

.icon {
  height: 80px;
  margin: 0 auto;
  width: 80px;
}

.title {
  color: $clr-white;
  font-weight: $gl-font-weight-semibold;
  text-align: center;
}
