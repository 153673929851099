@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.tabContainer {
  border-bottom: 2px solid #ebecef;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  background-color: #f2f0ee;
  // border-right: 2px solid #e2e2e6;

  &.active {
    border-bottom: 2px solid $clr-primary-500;

    .tab {
      color: $clr-primary;
      font-weight: normal;
    }
  }

  &:last-of-type {
    border-right: none;
  }
}

.tab {
  @include font-size($gl-font-size-xs);
  color: $clr-black-medium;
  font-weight: normal;
  line-height: 1.4;
  padding: 7px 0 6px;
  text-align: center;
  text-decoration: none;
  flex: auto;
  &:focus,
  &:hover {
    color: inherit;
    text-decoration: none;
  }

  &.hasInfoLink {
    padding-right: 20px;
  }

  @media screen and (max-width: 767px) {
    width: max-content;

    &.hasInfoLink {
      padding-right: 25px;
    }
  }
}

.infoLink {
  align-items: center;
  display: flex;
  margin-left: 10px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}
