@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
$progress-bar-height: 15px;
$progress-bar-width: 400px;

.buildButton {
  min-width: 205px;

  &.wide {
    width: 100%;
  }
}

.buildProgress {
  width: 100%;
  max-width: 400px;
}

.buildContainerButtonIcon {
  margin-right: 8px;
}

.buildContainerStatus {
  color: $clr-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}

.buildContainerStatusLabel {
  position: relative;
}

.buildContainerStatusDots {
  left: 101%;
  position: absolute;
}

.buildOutdated {
  @include font-size($gl-font-size-s);
  padding: 12px 16px;
  gap: 24px;
  max-width: 352px;
  min-height: 72px;

  background: #fff9c7;
  border: 1px solid #eb712c;
  border-radius: 8px;
  margin: 0 auto 16px;
  width: 100%;
}

.buildError {
  @include font-size($gl-font-size-s);
  color: $clr-danger;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 12px 14px 12px 16px;
  background: rgba(229, 57, 53, 0.15);
  border: 1px solid rgba(229, 57, 53, 0.25);
  border-radius: 8px;
  width: 100%;
  margin-bottom: 16px;
  max-width: 548px;
}

.buildErrorItem {
  display: inline;
  word-break: break-word;
  white-space: pre-wrap;
  overflow: hidden;

  &.collapsed {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.buildErrorLink {
  font-weight: $gl-font-weight-regular;
  color: $clr-danger;

  &:hover {
    color: $clr-danger;
    text-decoration: none;
  }
}

.buildErrorAppetize {
  width: 100%;
  max-width: 352px;
  margin: 0 auto 16px;
}

.buildErrorIcon {
  color: $clr-danger;
  height: fit-content;
  margin: 1px 5px 0 0;
}

.buildErrorCaret {
  color: $clr-danger;
  height: 25px;
  margin-top: -1px;
}

.buildErrorPlatform {
  color: $clr-danger;
  height: fit-content;
  margin-right: 5px;
}

.buildErrorContent {
  display: flex;
  gap: 2px;

  &.label {
    font-weight: $gl-font-weight-bold;
  }
}

.progressBarContainer {
  background-color: rgb(245, 245, 245);
  border-radius: 5px;
  display: flex;
  height: $progress-bar-height;
  margin: 0 auto 10px;
  overflow: hidden;
  width: 100%;
}

.progress {
  height: $progress-bar-height;
  border-radius: math.div($progress-bar-height, 2);
  transition: 1s ease;
  transition-delay: 0.5s;
  background: rgb(108, 219, 114)
    linear-gradient(
      135deg,
      transparent 25%,
      rgba(255, 255, 255, 0.2) 25%,
      rgba(255, 255, 255, 0.2) 60%,
      transparent 60%
    );
  background-size: 75px 100%;
  animation: progressAnimation 3s linear infinite;
  transform: scale(-1);
}

@keyframes progressAnimation {
  to {
    background-position: 75px 100%;
  }
}

.buildButtonNote {
  @include font-size($gl-font-size-s);
  box-sizing: border-box;
  padding: 12px 16px;
  gap: 8px;
  background: rgba(255, 160, 0, 0.15);
  border: 1px solid rgba(255, 160, 0, 0.25);
  border-radius: 8px;
  margin-top: 24px;
}

.buildContainer {
  width: 100%;

  &.preview {
    min-width: 336px;
  }
}

.buildButtonItems {
  display: flex;
  gap: 16px;
  max-width: 352px;

  &.center {
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  &.wide {
    display: block;
  }
}

.linkExternal {
  @include font-size($gl-font-size-s);
  font-weight: $gl-font-weight-bold;
  color: $clr-primary-500;
}

.lineBreak {
  display: none;
  @media screen and (min-width: 1510px) {
    display: block;
  }
}

// BuildErrorModal styles
.modal {
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 64px) !important;
  max-width: 1200px !important;
  height: 100%;
  width: 100%;

  &.modalHeight {
    height: fit-content;

    @media (max-width: 1200px) {
      height: 100%;
      border-radius: 0;
      max-height: none !important;
    }
  }

  @media (max-width: 1200px) {
    height: 100%;
    border-radius: 0;
    max-height: none !important;
  }
}

.modalHeader {
  flex-shrink: 0;
}

.modalContent {
  flex: 1;
  padding: 15px 30px 0;
}

.modalContentEditor {
  height: 100%;
  border: none !important;
}

.modalFooterButton {
  min-width: 120px;
  padding: 0 30px;
}

.hiddenDiv {
  overflow-wrap: break-word;
  position: absolute;
  max-width: 100%;
  visibility: hidden;
  padding: 0px 30px 0px 71px;
  line-height: 16px;
  max-height: 100%;
  overflow: hidden;
}
