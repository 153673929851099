@use "sass:math"; @import "mixins.scss"; @import "variables.scss";
.preview {
  display: flex;
  flex-direction: column;
  background-color: $clr-white;
  height: 100%;
  padding: 24px 22px 90px;
  min-width: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  max-width: min-content;

  &.collapsed {
    min-width: auto;
    padding: 18px 0 28px;
    width: 80px;
    max-width: none;
  }

  &.standalone {
    border: none;
    padding: 0;
    position: static;
    width: fit-content;
    margin: 24px auto;
    min-width: 420px;
    padding: 40px;
    border-radius: 16px;
    box-shadow: 3px 2px 16px 0px rgb(30 73 110 / 7%);

    @include phone {
      padding: 40px 12px;
      margin: 24px 0;
      min-width: 0;
      max-width: none;
      width: 100%;
      overflow: hidden;
    }
  }

  &.fullScreen {
    width: 100%;
    position: fixed;
    right: 0;
    z-index: $gl-z-index-overlay;
    min-width: auto;
    max-width: none;

    &.collapsed {
      position: relative;
      min-width: auto;
      padding: 18px 0 28px;
      width: 70px;

      @include mobile {
        display: none;
      }
    }

    @include phone {
      padding: 24px 10px 90px;
    }
  }

  @include mobile {
    &.collapsed {
      display: none;
    }
  }
}

.previewHeader {
  align-items: center;
  display: flex;
  padding-bottom: 20.5px;
  justify-content: space-between;

  &.collapsed {
    border: none;
    height: 100%;
    margin: 0;
  }
}

.previewHeaderTitle {
  font-size: $gl-font-size-overline;
  align-items: center;
  display: flex;
  font-weight: $gl-font-weight-regular;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: $clr-black-medium;
  margin: 0;

  &.collapsed {
    margin-top: 25px;
  }
}

.previewToggle {
  align-items: center;
  background: none;
  border: none;
  display: flex;
  height: 24px;
  justify-content: center;
  margin-right: 4px;
  outline: none;
  width: 24px;

  &.collapsed {
    height: 100%;
    margin: 0 auto;
    padding: 5px 10px 10px;
    width: auto;

    .previewToggleContent {
      flex-direction: row-reverse;
      height: 100%;
      justify-content: flex-end;
      writing-mode: tb;
    }
  }
}

.previewToggleContent {
  align-items: center;
  display: flex;
  justify-content: center;
}

.previewOptions {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  column-gap: 20px;
  row-gap: 16px;
}

.previewOptionsRow {
  align-items: center;
  display: flex;
  column-gap: 16px;
  row-gap: 8px;
  justify-content: center;

  .previewOptionsButtonsContainer {
    display: flex;
    gap: 16px;
    flex: 1;

    &.previewOptionsButtons {
      width: 336px;
      margin: 0 auto;
    }
  }
  &.selectModelRow {
    margin-bottom: 16px;

    &.standalone {
      margin-bottom: 18px;
    }
  }
  &.debugMode {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 30px;

    @include phone {
      &.isLicensed {
        margin-bottom: 75px;
      }
    }
  }

  &.topRow {
    justify-content: space-between;
  }
}

.previewOptionsButtonsItem {
  background-color: $clr-white-high;
  border: 1px solid $clr-gray-dark;
  border-radius: 8px;
  height: 44px;
  width: 44px;

  &:hover:not(.disabled) {
    background-color: $clr-primary-50;
  }
}

.previewOptionsDeviceTypes {
  width: 100%;
}

.previewOptionsDeviceTypesStandalone {
  min-width: 314px;
}

.previewOptionsSelectZoomMenu {
  flex: none;

  &.zoom {
    flex: 1;
  }

  &.iosModel {
    min-width: 168px;
  }

  &.androidModel {
    min-width: 136px;
  }

  &.os {
    width: auto;
  }

  &.iosModel {
    width: 200px;
  }

  @media screen and (max-width: 372px) {
    min-width: 0;
    max-width: 160px;
    flex: 1;
    width: auto !important;
  }
}

.rowWidth {
  max-width: 352px;
  width: 100%;
  margin: 0 auto;
}

.placeholderImage {
  cursor: not-allowed;
  filter: brightness(50%);

  &.transparent {
    opacity: 0.5;
  }
}

.placeholderImageContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;

  .overlay {
    position: absolute;
  }
}

.previewContent {
  margin: 16px auto;

  &.collapsed {
    display: none;
  }

  &.standalone {
    margin-top: 30px auto;
  }

  &.disabled {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.previewContentItem {
  border: none;

  &.disabled {
    display: none;
  }
}

.buttonExternal {
  align-items: center;
  color: $clr-black;
  display: flex;
  gap: 4px;
  text-decoration: none;
  height: 30px;
  padding: 0 10px;

  &:focus,
  &:hover {
    color: inherit;
    text-decoration: none;
  }

  &:disabled {
    cursor: default;
    opacity: 1;
  }
}

.standaloneButtons {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  max-width: 268px;
  width: 100%;
  align-self: center;

  &.debug {
    margin-bottom: 16px;
  }
}

.buildButtonNote {
  @include font-size($gl-font-size-xs);
  color: #585858;
  margin-top: 5px;
  white-space: pre-wrap;
  max-width: 400px;
}

.infoLink {
  @include font-size($gl-font-size-xs);
  -webkit-transition: 0.2s;
  color: #1a100b;
  cursor: pointer;
  font-weight: $gl-font-weight-bold;
  text-decoration: underline;
  transition: 0.2s;

  &:hover {
    color: #f7c406;
  }

  &:active {
    color: #1a100b;
  }
}

.tabContainer {
  display: flex;
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  gap: 0;
  overflow: hidden;
}

.tab {
  flex: auto;

  &.outdated {
    &::after {
      content: "";
      position: absolute;
      top: 5px;
      right: 3px;
      width: 5px;
      height: 5px;
      background-color: #eb712c;
      border-radius: 50%;
    }
  }
}

.previewLabelContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.previewLabel {
  font-size: $gl-font-size-xs;
  font-weight: $gl-font-weight-regular;
}

.select {
  min-width: 300px;
}

.noteAndButtonContainer {
  margin-top: 16px;

  &.building {
    display: block;
  }
}

.otherDevicesOptions {
  top: 36px;
}

.debugButton {
  min-width: 240px;
  text-align: center;
}

.downloadButton {
  &.download {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 16px;
  }
}

.download {
  width: 100%;
  min-width: 0 !important;
  max-width: 268px;
  margin: 0 auto;
}

.previewTopButtons {
  display: flex;
  gap: 4px;
  align-items: center;

  &.hidden {
    visibility: hidden;
  }
}

.debugSwitch {
  flex: 1;
  min-width: fit-content;
}

.zoomAndRotateContainer {
  display: flex;
  gap: 16px;
  flex: 1;
}

.cloneButton {
  width: 100%;
}

.sessionButtonsContainer {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 16px;

  &.standalone {
    gap: 8px;
    margin-bottom: 8px;
  }
}

.sessionButton {
  width: 100%;
  max-width: 268px;
}

.button {
  width: 100%;
}

.installAppButton {
  width: 100%;
}

.icon {
  color: $clr-background-light;

  &:hover {
    color: $clr-gray-divider;
  }
}

.divider {
  background-color: $clr-gray-divider;
  height: 1px;
  width: 100%;
  max-width: 268px;
  margin: 15px 0;
}
